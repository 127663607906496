<template>
  <div class="footyt">
    <div class="footyt_c">
      <p class="footyt_c_t">
        <span>How can find US？</span>
        <span>联系我们</span>
      </p>
      <div class="footyt_c_c" v-if="footobj != ''">
        <div class="footyt_c_cl">
          <div class="footyt_c_climg">
            <img :src="footobj[3].value" alt="" />
          </div>
          <ul class="footyt_c_clul">
            <li>
              ADD：
              {{ footobj[1].value }}
              <br />
              {{ footobj[0].value }}
            </li>
            <li>TEL：{{ footobj[2].value }}</li>
            <li>TEL：{{ footobj[4].value }}</li>
            <li>联系我们 {{ footobj[5].value }}</li>
          </ul>
        </div>
        <ul class="footyt_c_cr">
          <li>关于越唐 / About Us</li>
          <li>案例 / Case</li>
          <li>专业工作室 / Professional studio</li>
          <li>客户 / Customer</li>
          <li>新闻及动态 / Developments</li>
          <li>荣誉 / Honor</li>
          <li>论坛 / Forum</li>
        </ul>
      </div>
      <div class="footyt_c_b">
        <img :src="imgc" alt="" /><a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009319"
          >沪公网安备 31010402009319号</a
        ><a target="_blank" href="https://beian.miit.gov.cn/">
          沪ICP备17053650号-2 版权所有 Copynight · 2007-2021</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      footobj: [],
      imgc: "https://img.hozoin.cn/news_img/20210825/16298577410001568.png",
    };
  },
  methods: {
    axios(id) {
      let that = this;
      that
        .$axios({
          url: "/getSystem",
          method: "post",
        })
        .then((res) => {
          that.footobj = res.data.result;
        });
    },
  },
  created() {
    this.axios();
  },
};
</script>
<style>
@import "./../assets/css/foot.css";
</style>