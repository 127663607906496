<template>
  <div class="xm_anli">
    <div class="xm_anli_bna">
      <img
        src="https://img.hozoin.cn/news_img/2021086/16282136250001568.jpg"
        alt=""
      />
    </div>
    <ul class="teamul">
      <li
        class="teamli"
        v-for="(item, index) in teamularr"
        :key="index"
        @click="teamclick(item.id)"
      >
        <div class="teamli_l"><img :src="item.pic" alt="" /></div>
        <div class="teamli_r">
          <p>{{ item.gongzuoshi }}</p>
          <p>{{ item.gongzuoshien }}</p>
        </div>
      </li>
    </ul>
    <div class="teamtext">
      <p>
        越唐设计管理机构在企业发展同时，也很关注员工的职业发展。公司打造的是一个平台型企业，与员工之间建立的是以平等、尊重、信任、分享为基础的合作伙伴关系。公司为员工提供多样化、专业化的职业发展通道，为真正的有能力的人提供事业发展的舞台。
      </p>
      <p>
        越唐设计管理机构的最大核心价值在于“专业、品质、效率、服务、创新、责任”。公司发展要用好人才，而人才在公司的发展首先必须认同公司的核心价值观。
      </p>
      <p>我们倡导的是一个开放、协同、分享、追求卓越设计的文化氛围。</p>
    </div>
    <div class="xm_anli_bna" style="margin: 30px 0">
      <img
        src="https://img.hozoin.cn/news_img/2021086/16282136600001568.jpg"
        alt=""
      />
    </div>
    <div class="zpbox">
      <el-collapse v-model="activeNames" accordion @change="handleChange">
        <el-collapse-item
          v-for="(item, index) in recruitarr"
          :key="index"
          :title="item.title"
        >
          <div class="recruit">
            <p class="recruit_t">任职要求:</p>
            <p v-for="item_p in item.rz" :key="item_p">{{ item_p }}</p>
            <p v-if="item.gw != ''" class="recruit_t">岗位职责:</p>
            <p v-show="item.gw != ''" v-for="item_p in item.gw" :key="item_p">
              {{ item_p }}
            </p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <p class="zp_btext">
      请详细查看职位说明，您也可以直接将简历投递至我们邮箱 kaimenkaimen@126.com
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      teamularr: [],
      recruitarr: [
        {
          title: "品牌资深策略",
          rz: [
            "1.参与项目相关品牌策略的制定，从资料研究、策略导向到策略落地的全面工作；",
            "2.为设计人员提供策略支持，辅助客户沟通；参与提升部门现有的品牌建设相关工作；",
            "3.协助总监/主管研究提升工作方法，对所负责团队进行协调管理，保障项目顺利进行；",
            "4.协助总监/主管对部门进行日常管理，落实部门工作规范及流程；",
            "5.协助总监/主管对重点项目进行策略方向的制定；",
            "6.对相关项目策略的执行进行把控，撰写项目策划案。",
          ],
          gw: [
            "1. 广告学、市场营销、心理学等专业本科及以上学历；",
            "2.三年以上品牌建设、咨询行业工作经验，二年以上策划团队管理经验，有甲方品牌管理部门工作背景；",
            "3.熟练操作PPT、word、excel等办公软件；",
            "4.了解国内外品牌运作模式、市场发展情况，和国内品牌资询行业发展现状；",
            "5.具备丰富的独立带领团队为大型客户提供品牌咨询、品牌建设相关服务的经验。",
            "6.对现代企业战略管理和品牌战略有深刻的认识和理解，并形成有效的知识体系与方法；",
            "7.深厚的中文文字功底和策划文案写作能力，良好的英文读写能力；",
            "8.优秀的决策力和解决复杂问题的能力，善于沟通协调，责任心强，有团队合作精神；",
            "9.能承受多任务处理的工作压力，适应经常出差；",
          ],
        },
        {
          title: "广告文案",
          rz: [
            "1.大专及以上学历；",
            "2.上相关工作经验者优先；",
            "3.具有较强的文案写作能力，出色的文字组织能力，写作经验丰富，有丰富的提案经验；",
            "4.熟悉市场推广、品牌策划、活动策划的整个流程；",
            "5.拥有广告或策划经验，熟悉品牌与企业文化战略规划； ",
            "6.创意能力强、思维敏捷、善于沟通、具有良好的语言表达能力。",
          ],
          gw: [
            "1.负责公司市场营销活动方案的策划与组织实施，对外宣传资料和文案的撰写；",
            "2.根据业务需要制作各种宣传资料，塑造良好企业形象；",
            "3.根据公司市场战略和业务需要制定市场调查计划； ",
            "4.进行市场调查，收集相关行业市场信息，并整理、分析，形成报告；",
            "5.定期收集竞争对手信息，了解竞争对手的动态，并整理、分析，形成报告；",
          ],
        },
        {
          title: "装饰主案设计师",
          rz: [
            "1.装饰设计、建筑、艺术等相关专业专科以上学历；",
            "2.至少5年以上工装装饰行业工作经验，对市场材料掌握及精通施工工艺及细节。能画施工图、会草图大师，手绘方案能力强。",
            "3. 具有方案设计、效果规划、管理指导等综合能力。对于现场的问题能够及时解决。",
            "4.能主导个案进展。组织设计小组在概念方案基础上进行深化设计及施工图纸制作审核。把握整体设计风格并完成方案的整体设计 ",
            "5.负责完成公司初步设计、施工图的审核绘制等设计任务；",
            "6.控制下达的设计任务时间与进度，并及时向上级汇报进展情况。",
            "7.具原创性，有统合能力、完善方案能力与良好的团队沟通能力、管理能力；",
            "8.工作责任心强，具有良好的团队协作能力及沟通能力，能积极完成相关设计及现场协调工作。",
          ],
          gw: [],
        },
        {
          title: "施工图绘图员",
          rz: [
            "1.3年以上商业空间绘图经验，为人诚恳、热情、能吃苦耐劳，能对预算有所涉及。  ",
            "2.精通施工工艺，节点，材料特性及运用方式，施工图绘制精准规范，效率高。",
            "3.有能力从施工工艺角度向设计师提供建议，深化完善设计师提供的方案。",
            "4.精通软件能力：AutoCAD、Office、SU。",
            "5.主要职责：配合设计师深化方案，绘制规范的施工图纸。",
          ],
          gw: [],
        },
        {
          title: "规划设计师",
          rz: [
            "1.具有极强的敬业精神和责任感；",
            "2.具备一定的专业知识和项目经验,具有较强的设计思想、方案构思、创新理念，团队精神和优秀的沟通能力；",
            "3.设计完成不同类型的规划设计项目，主要是概念性详细规划、修建性详细规划、控制性详细规划等实际项目；",
            "4.熟练使用AutoCAD、Photoshop、Sketchup、office、GIS等软件，具有较强的手绘能力； ",
            "5.具有良好的分析和逻辑、沟通和协调、口头和文字表达能力；",
            "6.规划专业毕业、能胜任长期出差任务者优先。",
            "7.有专业规划设计院/公司工作背景者尤佳，有两年以上概念规划、城市设计或详细规划相关工作经验者优先考虑。",
          ],
          gw: [],
        },
        {
          title: "景观设计师",
          rz: [
            "1.具有优秀的创意能力和良好的手绘能力，擅长方案设计或施工图设计、施工图种植设计，熟悉景观设计 基本流程具有良好的艺术修养和审美意  识；熟悉AutoCAD，Photoshop，Sketch up，Office等相关软件；",
            "2.对项目方案有较强的掌控能力，设计思路开阔，对项目把握准确；",
            "3.能承担概念和深化设计以及指导各阶段的设计工作；",
            "4.能够贯穿从方案设计到施工图设计全程的综合能力，并具项目现场沟通和管理能力；   ",
            "5.具有良好的设计及沟通能力与团队合作能力，思维敏捷、灵活，学习能力强并具有良好的职业道德、文化修养。",
            "6.有独立的方案设计及组织能力，分析具体项目要求，组织完成整套景观方案的描述与表达；",
            "7.负责完成景观的方案设计、扩初设计和部分施工图设计；",
            "8.负责根据反馈调整设计方案，确保设计达到完美效果；",
            "9.协助主管对项目做必要的现场指导，确保项目符合建筑设计计划书；完成上级主管交付的其他工作。",
          ],
          gw: [],
        },
        {
          title: "室内设计主管",
          rz: [
            "1.设计艺术类专业本科以上学历，5年以上室内设计工作经验，酒店及高档别墅经验优先。  ",
            "2.丰富的想象力和创作激情，作品具有原创性和创新性?,有良好的团队合作精神。",
            "3.具备良好的性格，有较强的语言表达能力及扎实的手绘表现能力。",
            "4.有较强的组织领导和管理协调能力，责任心强，能承受工作压力。",
            "5.熟悉装潢材料及现场施工项目及施工现场指导经验，了解市场流行时尚的材料。",
            "6.能够独立完成案例，主创过酒店或高档别墅的相关案例。",
          ],
          gw: [
            "1.负责设计部的全面管理工作；",
            "2.做好项目的设计任务调派，监督指导设计人员的日常工作，对各项业务的设计进行审查；",
            "3.做好公司设计项目实施的组织工作；",
            "4.定期召开部门会议，计划安排学习、交流及总结，提高全体设计员的工作及业务水平；",
            "5.积极配合客户，按时完成设计工作。",
          ],
        },
        {
          title: "建筑设计师",
          rz: [
            "1.严格按照公司工作流程承担公司所开发某个项目的规划；",
            "2.建筑设计过程中与建设单位的衔接；",
            "3.对设计技术内容的掌握、对即定设计计划操作的执行与预警；   ",
            "4.现场施工中的相关设计工作的协调。",
          ],
          gw: [
            "1.建筑学或相关专业本科及以上学历；",
            "2.有三年以上相关工作经验；",
            "3.有较强的方案设计能力，能独立完成大型社区的规划设计，了解房地产市场需求；",
            "4.熟练使用建筑设计相关软件AUTOCAD、天正、SKETCHUP、PHOTOSHOP、PPT等或有较强的手绘表达能力。",
            "5.熟悉相关的建筑规范和设计流程；",
            "6.具较强的工作责任心及敬业精神，有团队合作精神。",
          ],
        },
      ],
      activeNames: "1",
    };
  },
  methods: {
    handleChange(e) {},
    teamclick(id) {
      this.$router.push({ path: "/team_c", query: { id } });
    },
    axios() {
      let that = this;
      that
        .$axios({
          url: "/getTeamList",
          method: "post",
          data: {
            page: "1",
            limit: "20",
          },
        })
        .then((res) => {
          that.teamularr = res.data.result.list;
        });
    },
  },
  created() {
    this.axios();
  },
};
</script>
<style>
@import "./../assets/css/team.css";
</style>