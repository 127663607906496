import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import about from '../views/about.vue'
import cases from '../views/case.vue'
import news from '../views/news.vue'
import news_c from '../views/news_c.vue'
import team from '../views/team.vue'
import customer from '../views/customer.vue'
import honor from '../views/honor.vue'
import team_c from '../views/team_c.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/about',
    name: 'about',
    component: about
}, {
    path: '/case',
    name: 'case',
    component: cases,
}, {
    path: '/news',
    name: 'news',
    component: news,
}, {
    path: '/news_c',
    name: 'news_c',
    component: news_c,
}, {
    path: '/team',
    name: 'team',
    component: team,
}, {
    path: '/team_c',
    name: 'team_c',
    component: team_c,
}, {
    path: '/honor',
    name: 'honor',
    component: honor,
}, {
    path: '/customer',
    name: 'customer',
    component: customer,
}, ]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export
default router;