<template>
  <div class="xm_anli">
    <div class="xm_anli_bna">
      <img
        src="https://img.hozoin.cn/news_img/2021086/16282133550001568.jpg"
        alt=""
      />
    </div>
    <ul class="customerul">
      <li v-for="(item, index) in ctm" :key="index">
        <img :src="item.image" alt="" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ctm: [],
    };
  },
  methods: {
    axios(id) {
      let that = this;
      that
        .$axios({
          url: "/getClientList",
          method: "post",
          data: {
            page: "1",
            limit: "100",
          },
        })
        .then((res) => {
          that.ctm = res.data.result.list;
        });
    },
  },
  created() {
    this.axios();
  },
};
</script>
<style>
@import "./../assets/css/home.css";
</style>