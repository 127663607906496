<template>
  <div class="aboutbox">
    <div v-if="result.length > 0">
      <div>{{ result[0].value }}</div>
      <div>{{ result[1].value }}</div>
    </div>
    <img
      src="https://img.hozoin.cn/news_img/2021089/16284731160001568.png"
      alt="孔"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: [],
    };
  },
  methods: {
    axios() {
      let that = this;
      that.result = {};
      that.loading = true;
      this.$axios({
        url: "/getAbout",
        method: "post",
        data: {},
      }).then((res) => {
        that.result = res.data.result;
      });
    },
  },
  created() {
    this.axios();
  },
};
</script>
<style>
@import "./../assets/css/home.css";
</style>