<template>
  <div class="xm_anli" style="min-height: 70vh">
    <div class="xm_anli_bna">
      <img
        src="https://img.hozoin.cn/news_img/2021086/16282134300001568.jpg"
        alt=""
      />
    </div>
    <ul class="newul">
      <li
        class="newli"
        v-for="(item, index) in newall"
        :key="index"
        @click="news_c(item.id)"
      >
        <div class="newliimg">
          <img
            :src="item.pic"
            @mouseenter="new_mse(index)"
            @mouseleave="new_msl(index)"
            alt=""
          />
        </div>
        <p>{{ item.title }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  data() {
    return {
      newall: [],
    };
  },
  methods: {
    new_mse(index) {
      let num = Number(index) + 1;
      let classname = ".newul li:nth-child(" + num + ") img";
      $(classname).css({
        animation: "newliimg_j 0.2s",
        "animation-fill-mode": "forwards",
      });
    },
    new_msl(index) {
      let num = Number(index) + 1;
      let classname = ".newul li:nth-child(" + num + ") img";
      $(classname).css({
        animation: "newliimg_c 0.2s",
        "animation-fill-mode": "forwards",
      });
    },
    axios(id) {
      let that = this;
      that
        .$axios({
          url: "/getNewsList",
          method: "post",
          data: {
            page: "1",
            limit: "50",
          },
        })
        .then((res) => {
          that.newall = res.data.result.list;
        });
    },
    news_c(id) {
      this.$router.push({ path: "/news_c", query: { id } });
    },
  },
  created() {
    this.axios();
  },
};
</script>
<style>
@import "./../assets/css/home.css";
</style>