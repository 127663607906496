<template>
  <div class="casebox">
    <div class="xm_anli">
      <div class="xm_anli_bna">
        <img
          src="https://img.hozoin.cn/news_img/2021086/16282135340001568.jpg"
          alt=""
        />
      </div>
      <ul class="classallul">
        <li
          v-for="(item, index) in classall"
          :key="index"
          @click="classclick(item.id, index)"
          :class="coseindex == index ? 'classallli_xz' : ''"
        >
          {{ item.class_name }}
        </li>
      </ul>
      <ul class="coseul">
        <li
          v-for="(item, index) in coseul"
          :key="index"
          @click="all(item.id, index)"
        >
          <img
            class="animateimg animate__animated animate__fadeInTopLeft"
            :src="item.pic"
            alt=""
          />
        </li>
      </ul>
      <div v-if="showModal" class="mask">
        <div class="xiangmu">
          <div class="xiangmu_l">
            <div class="xiangmu_l_t">
              <!-- <swiper
              :options="swiperOption"
              @slideChange="slideChange_xm"
              ref="mySwiper_xm"
              :normalizeSlideIndex='swiper_xmindex'
            >
              <swiper-slide v-for="(item, index) in swiper_one" :key="index">
                <div class="xiangmu_l_tbox"><img :src="item.img" alt="" /></div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper> -->
              <el-carousel
                indicator-position="none"
                @change="xiangmulbchange"
                :autoplay="false"
                height="80vh"
                ref="xiangmulb"
                arrow="always"
              >
                <el-carousel-item
                  v-for="(item, index) in result_xmall.product_img"
                  :key="index"
                >
                  <div class="xiangmu_l_tbox">
                    <img :src="item.pic" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <ul class="xiangmu_l_b">
              <li
                class="xiangmu_l_bli"
                v-for="(item, index) in result_xmall.product_img"
                :key="index"
                :class="swiper_xmindex == index ? 'xiangmu_l_bli_true' : ''"
                @click="xiangmu_l_bliclick(index)"
              >
                <img :src="item.pic" alt="" />
              </li>
            </ul>
          </div>
          <ul class="xiangmu_r">
            <li class="xiangmu_r_li">
              <div class="xiangmu_r_li_l">项目分类</div>
              <div class="xiangmu_r_li_r">{{ result_xmall.class_name }}</div>
            </li>
            <li class="xiangmu_r_li">
              <div class="xiangmu_r_li_l">项目名称</div>
              <div class="xiangmu_r_li_r">{{ result_xmall.title }}</div>
            </li>
            <li class="xiangmu_r_li">
              <div class="xiangmu_r_li_l"><span>客</span><span>户：</span></div>
              <div class="xiangmu_r_li_r">{{ result_xmall.kehu }}</div>
            </li>
            <li class="xiangmu_r_li">
              <div class="xiangmu_r_li_l"><span>简</span><span>介：</span></div>
              <div class="xiangmu_r_li_r">{{ result_xmall.intro }}</div>
            </li>
            <li class="xiangmu_r_li_close" @click="xiangmu_close">×</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  data() {
    return {
      classall: [],
      coseul: [],
      coseindex: 0,
      showModal: false,
      result_xmall: {},
      swiper_xmindex: 0,
    };
  },
  methods: {
    classclick(id, e) {
      let that = this;
      that.coseindex = e;
      $(".animateimg").addClass("animate__fadeOutTopLeft");
      setTimeout(() => {
        this.axios(id);
      }, 600);
    },
    classaxios() {
      let that = this;
      that
        .$axios({
          url: "/getCaseClass",
          method: "post",
          data: {
            page: "1",
            limit: "200",
          },
        })
        .then((res) => {
          let all = {
            class_name: "全部",
            id: "0",
          };
          let list = res.data.result.list;
          list.unshift(all);
          that.classall = list;
        });
    },
    axios(id) {
      let that = this;
      that.coseul = [];
      $(".animateimg").removeClass("animate__fadeOutTopLeft");
      that
        .$axios({
          url: "/getCaseList",
          method: "post",
          data: {
            page: "1",
            limit: "200",
            class: id,
          },
        })
        .then((res) => {
          that.coseul = res.data.result.list;
        });
    },
    all(id, e) {
      let that = this;
      this.axios_xmall(id);
      that.showModal = true;
    },
    axios_xmall(id) {
      let that = this;
      that
        .$axios({
          url: "/getCaseInfo",
          method: "post",
          data: {
            id,
          },
        })
        .then((res) => {
          that.result_xmall = res.data.result;
        });
    },
    xiangmulbchange(e) {
      this.swiper_xmindex = e;
    },
    xiangmu_l_bliclick(e) {
      this.$refs.xiangmulb.setActiveItem(e);
      this.swiper_xmindex = e;
    },
    xiangmu_close() {
      this.showModal = false;
    },
  },
  created() {
    this.axios("0");
    this.classaxios();
  },
};
</script>
<style>
@import "./../assets/css/case.css";
@import "./../assets/css/home.css";
</style>