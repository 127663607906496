<template>
  <div id="app">
    <headyt />
    <router-view />
    <footyt />
  </div>
</template>
<script>
import headyt from "./components/Head.vue";
import footyt from "./components/Foot.vue";
export default {
  components: {
    headyt,
    footyt,
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        let width = document.documentElement.clientWidth;
        let widths = Number(width / 138.1);
        if (widths < 12) {
          document.documentElement.style.fontSize = "12px";
        } else {
          document.documentElement.style.fontSize = "" + widths + "px";
        }
      })();
    };
  },
};
</script>
