import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import animated from 'animate.css'
Vue.use(animated)
import axios from 'axios'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
if (process.env.NODE_ENV === "development") {
    //生产环境
    axios.defaults.baseURL = "/href/yuetang";
} else {
    //线上环境
    axios.defaults.baseURL = "https://api.shyuetang.com";
};
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')