<template>
  <div class="xm_anli">
    <div class="xm_anli_bna">
      <img
        src="https://img.hozoin.cn/news_img/2021086/16282140810001568.jpg"
        alt=""
      />
    </div>
    <ul class="honorul">
      <li v-for="(item, index) in honorarr" :key="index">{{ item }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      honorarr: [],
    };
  },
  methods: {
    axios(id) {
      let that = this;
      that
        .$axios({
          url: "/getHonorList",
          method: "post",
        })
        .then((res) => {
          that.honorarr = res.data.result;
        });
    },
  },
  created() {
    this.axios();
  },
};
</script>