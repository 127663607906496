<template>
  <div class="home">
    <div class="home_swiper_big">
      <div class="home_swiper">
        <swiper :options="Option" @slideChange="slideChange" ref="mySwiper">
          <swiper-slide v-for="(item, index) in swiper_one" :key="index">
            <div class="home_swiper_box" @click="all(item.id, index)">
              <img :src="item.maxpic" alt="" />
              <div class="home_swiper_box_text">
                <p>{{ item.class_name }}</p>
                <p>客户：{{ item.kehu }}</p>
                <p>项目名称：{{ item.title }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-if="showModal" class="mask">
      <div class="xiangmu">
        <div class="xiangmu_l">
          <div class="xiangmu_l_t">
            <el-carousel
              indicator-position="none"
              @change="xiangmulbchange"
              :autoplay="false"
              height="80vh"
              ref="xiangmulb"
              arrow="always"
            >
              <el-carousel-item
                v-for="(item, index) in result_xmall.product_img"
                :key="index"
              >
                <div class="xiangmu_l_tbox"><img :src="item.pic" alt="" /></div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <ul class="xiangmu_l_b">
            <li
              class="xiangmu_l_bli"
              v-for="(item, index) in result_xmall.product_img"
              :key="index"
              :class="swiper_xmindex == index ? 'xiangmu_l_bli_true' : ''"
              @click="xiangmu_l_bliclick(index)"
            >
              <img :src="item.pic" alt="" />
            </li>
          </ul>
        </div>
        <ul class="xiangmu_r">
          <li class="xiangmu_r_li">
            <div class="xiangmu_r_li_l">项目分类：</div>
            <div class="xiangmu_r_li_r">{{ result_xmall.class_name }}</div>
          </li>
          <li class="xiangmu_r_li">
            <div class="xiangmu_r_li_l">项目名称：</div>
            <div class="xiangmu_r_li_r">{{ result_xmall.title }}</div>
          </li>
          <li class="xiangmu_r_li">
            <div class="xiangmu_r_li_l"><span>客</span><span>户：</span></div>
            <div class="xiangmu_r_li_r">{{ result_xmall.kehu }}</div>
          </li>
          <li class="xiangmu_r_li">
            <div class="xiangmu_r_li_l"><span>简</span><span>介：</span></div>
            <div class="xiangmu_r_li_r">{{ result_xmall.intro }}</div>
          </li>
          <li class="xiangmu_r_li_close" @click="xiangmu_close">×</li>
        </ul>
      </div>
    </div>
    <div class="xm_anli">
      <div class="xm_anli_bna">
        <img
          src="https://img.hozoin.cn/news_img/2021086/16282133050001568.jpg"
          alt=""
        />
      </div>
      <ul class="xm_anli_ul">
        <li
          v-for="(item, index) in xm_anliall"
          :key="index"
          @mouseenter="mouseenter(index)"
          @mouseleave="mouseleave(index)"
          @click="all(item.id, index)"
        >
          <img :src="item.img" alt="" />
          <div class="xm_anli_ul_zz">
            <p>{{ item.title }}</p>
            <p>查看案例</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="xm_anli">
      <div class="xm_anli_bna">
        <img
          src="https://img.hozoin.cn/news_img/2021086/16282134300001568.jpg"
          alt=""
        />
      </div>
      <ul class="newul">
        <li
          class="newli"
          v-for="(item, index) in newall"
          :key="index"
          v-show="index < 3"
          @click="news_c(item.id)"
        >
          <div class="newliimg">
            <img
              :src="item.pic"
              @mouseenter="new_mse(index)"
              @mouseleave="new_msl(index)"
              alt=""
            />
          </div>
          <p>
            <span>{{ item.create_time }}</span
            >{{ item.title }}
          </p>
        </li>
      </ul>
    </div>
    <customer />
  </div>
</template>

<script>
import $ from "jquery";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import customer from "../views/customer.vue";
export default {
  components: {
    swiper,
    swiperSlide,
    customer,
  },
  data() {
    return {
      Option: {
        autoplay: {
          delay: 100000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        initialSlide: 1,
      },
      swiper_one: [],
      sw_num: 0,
      showModal: false,
      swiper_xmindex: 0,
      xm_anliall: [
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282129090001568.jpg",
          title: "中电国际新能源控股有限公司",
          id: "52",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282129400001568.jpg",
          title: "杜营河",
          id: "19",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282129550001568.jpg",
          title: "中韩国际影音基地—悠港",
          id: "35",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282129860001568.jpg",
          title: "招商证券",
          id: "54",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282129900001568.jpg",
          title: "嘟捞咪坊时尚餐厅",
          id: "28",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282130220001568.jpg",
          title: "文登市创意经济产业园",
          id: "9",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282130400001568.jpg",
          title: "apd国际包装协会",
          id: "44",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282130680001568.jpg",
          title: "中航信托",
          id: "53",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282130870001568.jpg",
          title: "中航财富全国营业部",
          id: "43",
        },
        {
          img: "https://img.hozoin.cn/news_img/2021086/16282131230001568.jpg",
          title: "芬兰阿旺特",
          id: "25",
        },
      ],
      a_all: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
      newall: [],
      result_xmall: {},
    };
  },
  methods: {
    slideChange(e) {
      let activeIndex = this.swiper.activeIndex;
      let length = this.swiper_one.length - 1;
      if (activeIndex == length) {
        setTimeout(() => {
          this.swiper.slideTo(1, 1000, false);
        }, 3000);
      }
    },
    all(id, e) {
      let that = this;
      this.axios_xmall(id);
      that.showModal = true;
    },
    xiangmu_l_bliclick(e) {
      this.$refs.xiangmulb.setActiveItem(e);
      this.swiper_xmindex = e;
    },
    xiangmulbchange(e) {
      this.swiper_xmindex = e;
    },
    xiangmu_close() {
      this.showModal = false;
    },
    mouseenter(index) {
      let num = Number(index) + 1;
      let classname = ".xm_anli_ul li:nth-child(" + num + ") .xm_anli_ul_zz";
      if (this.a_all[index] == "0") {
        $(classname).css({
          animation: "a" + num + "_j 1s",
          "animation-fill-mode": "forwards",
        });
        this.a_all[index] = "1";
        setTimeout(() => {
          this.a_all[index] = "0";
        }, 1000);
      } else {
        setTimeout(() => {
          $(classname).css({
            animation: "a" + num + "_c 1s",
            "animation-fill-mode": "forwards",
          });
        }, 1000);
      }
    },
    mouseleave(index) {
      let num = Number(index) + 1;
      let classname = ".xm_anli_ul li:nth-child(" + num + ") .xm_anli_ul_zz";
      if (this.a_all[index] == "1") {
        setTimeout(() => {
          $(classname).css({
            animation: "a" + num + "_c 1s",
            "animation-fill-mode": "forwards",
          });
        }, 1000);
      } else {
        $(classname).css({
          animation: "a" + num + "_c 1s",
          "animation-fill-mode": "forwards",
        });
        this.a_all[index] = "1";
        setTimeout(() => {
          this.a_all[index] = "0";
        }, 1000);
      }
    },
    new_mse(index) {
      let num = Number(index) + 1;
      let classname = ".newul li:nth-child(" + num + ") img";
      $(classname).css({
        animation: "newliimg_j 0.2s",
        "animation-fill-mode": "forwards",
      });
    },
    new_msl(index) {
      let num = Number(index) + 1;
      let classname = ".newul li:nth-child(" + num + ") img";
      $(classname).css({
        animation: "newliimg_c 0.2s",
        "animation-fill-mode": "forwards",
      });
    },
    axiosnew() {
      let that = this;
      $(".animateimg").removeClass("animate__fadeOutTopLeft");
      that
        .$axios({
          url: "/getNewsList",
          method: "post",
          data: {
            page: "1",
            limit: "50",
          },
        })
        .then((res) => {
          that.newall = res.data.result.list;
        });
    },
    axios_xm() {
      let that = this;
      that
        .$axios({
          url: "/getCaseBanner",
          method: "post",
          data: {
            page: "1",
            limit: "20",
          },
        })
        .then((res) => {
          that.swiper_one = res.data.result.list;
        });
    },
    axios_xmall(id) {
      let that = this;
      that
        .$axios({
          url: "/getCaseInfo",
          method: "post",
          data: {
            id,
          },
        })
        .then((res) => {
          that.result_xmall = res.data.result;
        });
    },
    news_c(id) {
      this.$router.push({ path: "/news_c", query: { id } });
    },
  },
  created() {
    this.axiosnew();
    this.axios_xm();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    setTimeout(() => {
      this.swiper.slideTo(1, 1000, false);
    }, 100);
  },
};
</script>
<style>
@import "./../assets/css/home.css";
</style>
