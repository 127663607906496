<template>
  <div class="headbig">
    <div class="headbox">
      <div class="headbox_l">
        <img
          src="https://img.hozoin.cn/news_img/2021086/16282146410001568.jpg"
          alt=""
        />
      </div>
      <ul class="headbox_r">
        <li
          v-for="(item, index) in ul"
          :key="index"
          :class="active == item.a ? 'active' : ''"
        >
          <router-link :to="item.a">{{ item.t }}</router-link>
        </li>
      </ul>
    </div>
    <div class="headervideo">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      >
      </video-player>
    </div>
    <img
      @click="xm_link"
      class="headbox_bimg"
      src="https://img.hozoin.cn/news_img/2021086/16282132640001568.jpg"
      alt=""
    />
  </div>
</template>
<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      active: "/",
      ul: [
        {
          t: "首页",
          a: "/",
        },
        {
          t: "关于越唐",
          a: "/about",
        },
        {
          t: "项目案例",
          a: "/case",
        },
        {
          t: "新闻及动态",
          a: "/news",
        },
        {
          t: "客户",
          a: "/customer",
        },
        {
          t: "专业团队",
          a: "/team",
        },
        {
          t: "荣誉",
          a: "/honor",
        },
      ],
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "https://img.hozoin.cn/video/20210805/2e6d8cd498c0e1964283e7e79270c488.mp4", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: false,
      },
    };
  },
  methods: {
    xm_link() {
      this.$router.push({ path: "/case" });
    },
    backtop() {
      let nowTop =
        document.body.scrollTop || document.documentElement.scrollTop; // 获取当前滚动条位置

      let stm = function () {
        setTimeout(() => {
          window.scrollTo(0, nowTop - 100);
          nowTop = nowTop - 100;
          if (nowTop > 0) {
            stm();
          }
        }, 10);
      };
      stm();
    },
  },
  watch: {
    $route(to, from) {
      var topath = to.path;
      this.active = topath;
      this.backtop();
    },
  },
};
</script>
<style>
@import "./../assets/css/head.css";
.active a {
  color: rgb(70, 224, 248);
}
</style>