<template>
  <div class="xm_anli">
    <div class="xm_anli_bna">
      <img
        src="https://img.hozoin.cn/news_img/2021086/16282136250001568.jpg"
        alt=""
      />
    </div>
    <div class="team_c">
      <div class="elprev" @click="arrowClick('prev')">
        <i class="el-icon-arrow-left"></i>
      </div>
      <template>
        <el-carousel
          ref="cardShow"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-text="拼命加载中"
          :interval="5000"
          height="500px"
          indicator-position="none"
          @change="changeteam"
          :autoplay="false"
          arrow="never"
        >
          <el-carousel-item v-for="(item, index) in teamularr" :key="index">
            <div class="team_cbox">
              <div class="team_cbox_l">
                <img :src="result.pic" alt="" />
              </div>
              <div class="team_cbox_r">
                <h3 class="team_cbox_r_p1">{{ result.gongzuoshi }}</h3>
                <p class="team_cbox_r_p2">{{ result.gongzuoshien }}</p>
                <p class="team_cbox_r_p3">{{ result.gongzuoshiinro }}</p>
                <h2 class="team_cbox_r_p4">{{ result.name }}</h2>
                <div v-html="result.intro"></div>
                <p v-if="result.yejiintro" class="lead">项目业绩</p>
                <div v-if="result.yejiintro" v-html="result.yejiintro"></div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </template>
      <div class="elnext" @click="arrowClick('next')">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: {},
      teamularr: [],
      loading: false,
    };
  },
  methods: {
    axios(id) {
      let that = this;
      that.result = {};
      that.loading = true;
      this.$axios({
        url: "/getTeamInfo",
        method: "post",
        data: {
          id,
        },
      }).then((res) => {
        that.result = res.data.result;
        that.loading = false;
      });
    },
    listarr() {
      let that = this;
      that
        .$axios({
          url: "/getTeamList",
          method: "post",
          data: {
            page: "1",
            limit: "20",
          },
        })
        .then((res) => {
          that.teamularr = res.data.result.list;
        });
    },
    changeteam(e) {
      let that = this;
      let id = that.teamularr[e].id;
      that.axios(id);
    },
    arrowClick(val) {
      if (val == "next") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
  },
  created() {
    let id = this.$route.query.id;
    this.axios(id);
    this.listarr();
  },
};
</script>
<style>
@import "./../assets/css/team.css";
</style>