<template>
  <div class="news_c">
    <p class="news_c_title">{{ news.title }}</p>
    <div class="news_c_text" v-html="news.contents"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      news: {},
    };
  },
  methods: {
    axios(id) {
      let that = this;
      that
        .$axios({
          url: "/getNewsInfo",
          method: "post",
          data: {
            id,
          },
        })
        .then((res) => {
          that.news = res.data.result;
        });
    },
  },
  created() {
    let id = this.$route.query.id;
    this.axios(id);
  },
};
</script>
<style>
@import "./../assets/css/home.css";
</style>